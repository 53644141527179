import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Routes } from "react-router-dom";
import { AccessDeniedPopup } from "views/auth/AccessDeniedPopup";

import {
  API_DEFINITION_PATH,
  APIDefinition,
} from "./views/api-definition/APIDefinition";
import { APIsList } from "./views/apis-list/APIsList";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/api-list" element={<APIsList />} />
        <Route path="/access-denied" element={<AccessDeniedPopup />} />
        <Route path={API_DEFINITION_PATH} element={<APIDefinition />} />
        <Route path="/*" element={<App />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("ace-root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
