import { createSelector } from "@reduxjs/toolkit";
import createCachedSelector from "re-reselect";

import { StepListItem } from "../../components/flow/step-list/StepList";
import { sortAscInsensitive } from "../../components/utils/filterAndSort";
import { Flow, Step, Steps, VirtualStep } from "../../model";
import { FileType, Folder } from "../../model/file";
import { findNode, getAllChildNodes } from "../../services/nodes";
import { serializeId } from "../../services/references";
import { DropdownOption } from "../../utils/dropdownOption";
import { ALL_NODES_ID, ROOT_NODE_ID } from "../designer/constants";
import {
  selectFlowSelectedFolderId,
  selectPreviewFlowId,
} from "../designer/pageSelectors";
import { selectSelectedFlowStep } from "../designer/selectors";
import { RootState } from "../index";
import { getEntityTargetFolderPath } from "../utils/getEntityTargetPath";
import { omitExtension } from "../utils/path";
import { GenericState, Redoable } from "../utils/redoableSliceFactory";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { selectDropdownOptions } from "../utils/selectors/selectDropdownOptions";
import {
  selectPresentVirtualSteps,
  selectVirtualStepName,
} from "../virtual-steps/selectors";
import {
  selectFlowFolder,
  selectNodes,
  selectOpenFlowIds,
} from "../workspaces/selectors";

import { findNestedStep } from "./reducerUtilities";

export const selectFlowEntity = (
  state: RootState,
  flowId: string
): Redoable<Flow> | undefined => selectEntity(state.flows, flowId);

export const selectFlow = createSelector(
  selectFlowEntity,
  (flow) => flow?.present
);

export const selectFlowHistoryStates = createSelector(
  selectFlowEntity,
  (flow) => ({
    hasFutureStates: flow ? flow.future.length > 0 : false,
    hasPastStates: flow ? flow.past.length > 0 : false,
  })
);

const mapStepForStepList = (
  step: Step,
  selectedStepId: string | undefined
): StepListItem => ({
  id: step.id,
  isSelected: selectedStepId === step.id,
  subtitle: step.stepType,
  title: step.name,
  step,
});

const mapVirtualStepInstanceForStepList = (
  step: Step,
  selectedStepId: string | undefined,
  virtualSteps: VirtualStep[]
): StepListItem => {
  const virtualStep = virtualSteps.find(
    ({ id }) => serializeId(id) === step.virtualStepId
  );

  const id = step.id;
  const isSelected = selectedStepId === step.id;

  const subtitle = virtualStep
    ? selectVirtualStepName(virtualStep)
    : `Virtual step '${step.virtualStepId}' not found`;

  return {
    id,
    isSelected,
    subtitle,
    title: step.name,
    step,
    error: !virtualStep,
  };
};

export const selectFlowStepList = createCachedSelector(
  selectFlow,
  selectSelectedFlowStep,
  selectPresentVirtualSteps,
  (flow, selectedStepId, virtualSteps) => {
    const steps = flow?.steps || [];

    return steps.map((step) => {
      if (step.stepType === Steps.VIRTUAL) {
        return mapVirtualStepInstanceForStepList(
          step,
          selectedStepId,
          virtualSteps
        );
      } else {
        return mapStepForStepList(step, selectedStepId);
      }
    });
  }
)((_state, flowId) => flowId);

const selectFlowSlice = (state: RootState): GenericState<Flow> => state.flows;

// TODO: replace reduce with map once IDs are properly mapped to serializedId(flow)
// or something else, as there should be no case when openFlows contain non-existing flows
export const selectOrderedFlows: (state: RootState) => Flow[] = createSelector(
  selectFlowSlice,
  selectOpenFlowIds,
  (slice, ids) =>
    ids.reduce<Flow[]>((acc, id) => {
      const flow = slice.find(
        (redoableFlow: Redoable<Flow>) => id === redoableFlow.present.id
      )?.present;
      if (flow) acc.push(flow);
      return acc;
    }, [])
);

export const selectFlowSelectedStep = createSelector(
  selectFlow,
  selectSelectedFlowStep,
  (flow, selectedStepId) => {
    const steps = flow?.steps || [];

    if (!selectedStepId) return undefined;

    for (const entity of steps) {
      if (entity.stepType === Steps.SWITCH) {
        const casesStep = findNestedStep(
          // eslint-disable-next-line
          // @ts-expect-error: nested cases typing
          entity.cases,
          selectedStepId
        );
        if (casesStep) return casesStep;

        const defaultStep = findNestedStep(
          entity.defaultCase as Step[],
          selectedStepId
        );
        if (defaultStep) return defaultStep;
      }

      if (entity.id === selectedStepId) return entity;
    }

    return undefined;
  }
);

export const selectPreviewFlow = createSelector(
  (state: RootState) => state.flows,
  selectPreviewFlowId,
  (flows, selectedPreviewFlowId) => {
    if (selectedPreviewFlowId) {
      const flow = selectEntity(flows, selectedPreviewFlowId);

      return flow?.present;
    }

    return undefined;
  }
);

export const nameSelector = (flow: Flow): string => flow.name;

const reduceToFlowListItems = (items: Folder) =>
  items.reduce<Array<FlowListItem>>((result, node) => {
    if (node.type === FileType.File) {
      result.push({
        id: node.id,
        name: node.displayName,
        date: node.date,
        tags: [],
        path: node.path,
      });
    }

    return result;
  }, []);

interface FlowListItem {
  id: string;
  name: string;
  date: string;
  tags: string[];
  path: string;
}

export const selectFlowListItems = createSelector(
  selectFlowFolder,
  selectFlowSelectedFolderId,
  (flowsFolder, selectedFolderId) => {
    if (selectedFolderId === undefined || selectedFolderId === ALL_NODES_ID) {
      return flowsFolder.reduce<Array<FlowListItem>>((result, node) => {
        if (node.type === FileType.File) {
          result.push({
            id: node.id,
            name: node.displayName,
            date: node.date,
            tags: [],
            path: node.path,
          });
        }

        const childNodes = getAllChildNodes(node, true).map((n) => ({
          id: n.id,
          name: n.displayName,
          date: n.date,
          tags: [],
          path: n.path,
        }));

        return [...result, ...childNodes];
      }, []);
    }

    if (selectedFolderId === ROOT_NODE_ID) {
      return reduceToFlowListItems(flowsFolder);
    }

    const node = findNode(selectedFolderId, flowsFolder);

    if (node) {
      return reduceToFlowListItems(node.children);
    }

    return [];
  }
);

export const selectSelectedFlowsFolderItems = createSelector(
  selectFlowFolder,
  selectFlowSelectedFolderId,
  (flowsFolder, selectedFolderId) => {
    if (selectedFolderId === undefined || selectedFolderId === ALL_NODES_ID) {
      return flowsFolder.filter((flow) => flow.children.length === 0);
    }

    const node = findNode(selectedFolderId, flowsFolder);

    if (node) {
      return node.children.filter((flow) => flow.children.length === 0);
    }

    return [];
  }
);

export const selectFlowPaths = createSelector(
  (state: RootState) => selectNodes(state, "flows", ALL_NODES_ID),
  (nodes) =>
    nodes.reduce<Record<string, string>>(
      (before, { id, path }) => ({ ...before, [id]: omitExtension(path) }),
      {}
    )
);

export const selectSortedFlowDropdownOptions = (
  state: RootState
): DropdownOption[] =>
  selectDropdownOptions(state, "flows").sort((a, b) =>
    sortAscInsensitive(a.label, b.label)
  );

export const selectFlowTargetFolderPath = createSelector(
  selectFlowEntity,
  getEntityTargetFolderPath
);

export const selectTargetFolderPathsOfOpenedFlows = createSelector(
  selectFlowSlice,
  (flows) =>
    flows.reduce<Record<string, string>>((acc, f) => {
      const entityTargetFolderPath = getEntityTargetFolderPath(f);
      if (entityTargetFolderPath) acc[f.present.id] = entityTargetFolderPath;
      return acc;
    }, {})
);
