import { DEFAULT_VARIABLES_FILENAME } from "@sapiens-digital/ace-designer-common";
import { Logger } from "@sapiens-digital/ace-designer-common/lib/logging";
import * as workspacePaths from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import { SerializedEnvVariable, SerializedEnvVariables } from "model/variable";
import path from "path";
import { readYaml } from "services/fs-utils";
import { createEmptyVariable } from "utils/factory";
import { createSortYamlMapEntries } from "utils/sortYaml";

import { YAMLStringifyOptions } from "../../fs-utils";
import { saveAsYaml } from "../migrate/migrateUtils";

const WORKSPACE_VARIABLE_KEYS = ["name", "value"];

const yamlOptions: YAMLStringifyOptions = {
  sortMapEntries: createSortYamlMapEntries(WORKSPACE_VARIABLE_KEYS),
};

export const mergeWorkspaceVariablesContent = (
  source: SerializedEnvVariable[],
  dest: SerializedEnvVariable[]
): SerializedEnvVariable[] => {
  source.forEach((variable) => {
    const cpyVariable = dest.find((v) => v.name === variable.name);
    if (cpyVariable) {
      cpyVariable.value = variable.value;
    } else dest.push(variable);
  });

  return dest;
};

export async function readWorkspaceVariableFile(
  repositoryLocation: string
): Promise<SerializedEnvVariables> {
  try {
    return (await readYaml(
      path.join(
        repositoryLocation,
        workspacePaths.WORKSPACE_VARIABLES,
        `${DEFAULT_VARIABLES_FILENAME}.yaml`
      )
    )) as SerializedEnvVariables;
  } catch (e) {
    Logger.getLogger("importWorkspace").debug(
      `Creating new ${DEFAULT_VARIABLES_FILENAME}.yaml file.`
    );
    console.warn("Cannot read workspace variable file", e);
    return createEmptyVariable();
  }
}

export const saveWorkspaceVariablesFile = async (
  targetRepositoryLocation: string,
  variables: SerializedEnvVariable[]
): Promise<void> => {
  const migration = {
    variables,
  };
  const fileBasename = "env";

  await saveAsYaml(
    targetRepositoryLocation,
    workspacePaths.WORKSPACE_VARIABLES,
    fileBasename,
    migration,
    yamlOptions
  );
};

export const importWorkspaceVariables = async (
  sourceRepositoryLocation: string,
  repositoryLocation: string
): Promise<void> => {
  const sourceFile = await readWorkspaceVariableFile(
    sourceRepositoryLocation as string
  );
  const destFile = await readWorkspaceVariableFile(
    repositoryLocation as string
  );
  destFile.variables = mergeWorkspaceVariablesContent(
    sourceFile.variables,
    destFile.variables
  );

  await saveWorkspaceVariablesFile(repositoryLocation, destFile.variables);
};
