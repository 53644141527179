import { Status } from "../../components/utils/useWatchedSelect";
import { CommonPageData, Designer, FileTreeData } from "../../model";

import { ALL_NODES_ID, ROOT_NODE_ID } from "./constants";

const initialCommonPageData: CommonPageData = {
  selectedItemId: Status.Deselected,
  selectedPage: 1,
  searchTerm: "",
  sort: "",
  activeToggleFilters: [],
  unsavedEntity: null,
};
const initialFileTreeData: FileTreeData = {
  selectedFolderId: ALL_NODES_ID,
  selectedTreeItemId: "",
  expandedFolderIds: [ROOT_NODE_ID],
};
export const initialPageStateData: Readonly<Designer["pages"]> = {
  flow: {
    view: "selector",
    selectedStepId: "",
    ...initialCommonPageData,
    ...initialFileTreeData,
  },
  api: {
    ...initialCommonPageData,
    ...initialFileTreeData,
    selectedFolderId: ROOT_NODE_ID,
    selectedTreeItemId: ROOT_NODE_ID,
  },
  scheduler: {
    ...initialCommonPageData,
  },
  schemas: {
    expandedSchemas: [],
    ...initialCommonPageData,
    ...initialFileTreeData,
  },
  virtualSteps: {
    expandedSteps: [],
    ...initialCommonPageData,
    ...initialFileTreeData,
  },
  handlers: {
    ...initialCommonPageData,
  },
  variables: {
    ...initialCommonPageData,
    ...initialFileTreeData,
  },
};

export const initialState: Readonly<Designer> = {
  isDesignerLoading: true,
  settingsOpen: false,
  workspaceManagerOpen: false,
  version: "",
  showSideNav: true,
  selectedStepIds: {},
  notifications: [],
  hasWorkspaceConflicts: false,
  localRepositoryWarning: false,
  entityValidationErrors: {},
  flowTestResult: "",
  flowsSourceViewEnabled: {},
  stepUi: {},
  flowUi: {},
  schedulerUi: {},
  isIndexing: false,
  pages: initialPageStateData,
};
