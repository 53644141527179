import * as workspacePaths from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import get from "lodash/get";
import { createSortYamlMapEntries } from "utils/sortYaml";

import { YAMLStringifyOptions } from "../../fs-utils";

import { PlainObject, V1EntityMigrator } from "./migrate";
import { saveAsYaml } from "./migrateUtils";

const SCHEMA_KEYS = ["title", "properties", "type", "required"];

const yamlOptions: YAMLStringifyOptions = {
  sortMapEntries: createSortYamlMapEntries(SCHEMA_KEYS),
};

export const extractSchemaFileName = (
  content: unknown,
  defaultName: string
): string => get(content, "nodeKey") || get(content, "title") || defaultName;

const migrateSchema: V1EntityMigrator = async ({
  targetRepositoryLocation,
  fileBasename,
  content,
}) => {
  let migration = content as PlainObject;
  const name = extractSchemaFileName(migration, fileBasename);

  delete migration["nodeKey"];
  migration = mapRefs(migration);

  await saveAsYaml(
    targetRepositoryLocation,
    workspacePaths.WORKSPACE_API_SCHEMAS,
    name,
    migration,
    yamlOptions
  );
  return [];
};

const mapRefs = (property: PlainObject): PlainObject => {
  for (const key in property) {
    if (typeof property[key] === "object") {
      mapRefs(property[key] as PlainObject);
    } else if (key === "$ref") {
      const value = property[key] as string;
      const values = value.split("/");
      property[key] = values[values.length - 1] + ".yaml";
    }
  }

  return property;
};

export default migrateSchema;
