import { RepositoryDetails } from "../model/git";
import { HEADER_ENCODED_HEADERS } from "../model/aceHeaders";
import { getBase64EncodedHeaders } from "./getbase64EncodedOrDecodedHeaders";

export type EncodedHeaders = { [HEADER_ENCODED_HEADERS]: string };

export const createAceHeaders = (
  repoDetails: RepositoryDetails,
  authorizationToken: string
): EncodedHeaders => {
  return getBase64EncodedHeaders({
    repository: repoDetails.repository,
    token: repoDetails.token,
    branch: repoDetails.branch,
    authorization: authorizationToken,
    commit: repoDetails.commit,
    username: repoDetails.username,
    repositoryWorkspacePath: repoDetails.repositoryWorkspacePath,
  });
};
