import updateStepConfigWithCustomDefault from "@sapiens-digital/ace-designer-common/lib/helpers/stepHelper";
import * as workspacePaths from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import get from "lodash/get";
import set from "lodash/set";
import unset from "lodash/unset";
import { generateSampleObjectFromSchema } from "utils/jsonSchemaFaker";
import { createSortYamlMapEntries } from "utils/sortYaml";

import { SerializedFlow } from "../../../model";
import { YAMLStringifyOptions } from "../../fs-utils";

import { PlainObject, V1EntityMigrator } from "./migrate";
import { extractFileName, saveAsYaml } from "./migrateUtils";

const DEPRECATED_STEP_FIELDS = [
  "color",
  "textColor",
  "isSelected",
  "isChecked",
  "displayName",
];
const FLOW_KEY_PRIORITY = [
  "name",
  "description",
  "tags",
  "version",
  "sampleInputSchema",
  "sampleData",
  "steps",
];

const yamlOptions: YAMLStringifyOptions = {
  sortMapEntries: createSortYamlMapEntries(FLOW_KEY_PRIORITY),
};

export const transformFlow = (
  content: PlainObject | PlainObject[]
): SerializedFlow => {
  const migration = {};

  set(migration, "tags", get(content, "tags", []));
  set(migration, "steps", get(content, "flow.steps", []));

  const description = get(content, "flow.description");
  const inputSchemaLabel = get(content, "inputSchemaLabel");
  const inputSchema = get(content, "inputSchema");

  if (description) set(migration, "description", description);
  if (inputSchemaLabel) set(migration, "sampleInputSchema", inputSchemaLabel);

  if (inputSchema) {
    const sampleData = attemptGenerateSampleObjectFromSchema(inputSchema);
    set(migration, "sampleData", sampleData);
  }

  const steps = get(migration, "steps");
  Array.isArray(steps) &&
    steps.forEach((step) => {
      set(step, "name", get(step, "displayName", ""));
      set(step, "description", get(step, "description", ""));
      set(step, "condition", get(step, "condition", ""));
      set(step, "config", get(step, "config", {}));
      DEPRECATED_STEP_FIELDS.forEach((field) => unset(step, field));
      updateStepConfigWithCustomDefault(step);
    });

  return migration as SerializedFlow;
};

const attemptGenerateSampleObjectFromSchema = (
  inputSchema: PlainObject
): unknown | undefined => {
  try {
    return generateSampleObjectFromSchema(inputSchema);
  } catch (e) {
    console.warn(
      "Migrated input schema was an invalid schema, assuming plain data was passed instead"
    );
    return inputSchema;
  }
};

const migrateFlow: V1EntityMigrator = async ({
  targetRepositoryLocation,
  fileBasename,
  content,
}) => {
  const flow = transformFlow(content);
  const name = extractFileName(content, fileBasename);

  await saveAsYaml(
    targetRepositoryLocation,
    workspacePaths.WORKSPACE_FLOWS,
    name,
    flow,
    yamlOptions
  );

  return [];
};

export default migrateFlow;
