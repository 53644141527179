import { PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import isUndefined from "lodash/isUndefined";
import { Steps } from "model";
import YAML from "yaml";

import { Flow } from "../../model/flow";
import { Step } from "../../model/step";
import { serializeFlow } from "../../services/flows";
import { createRedoableSlice } from "../utils/redoableSliceFactory";
import {
  redoEntity,
  undoEntity,
  updateEntityWithDraft,
} from "../utils/redoableSliceMutators";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { reorder } from "../utils/reorder";
import { closeFlowTabAction } from "../workspaces/tabActions";

import {
  deleteFlowAction,
  deleteFlowFolderAction,
  generateDataForInputSchemaAction,
  loadFlowAction,
  openFlowAction,
  overwriteFlowFolderAction,
  refreshFlowsAction,
  storeFlowAction,
} from "./actions";
import {
  deleteNestedStep,
  findNestedStep,
  getModifiableStepIndex,
  modifyNestedStep,
  updateFlowEntity,
  updateNestedStep,
} from "./reducerUtilities";
import { name } from "./sliceName";

const flowsSlice = createRedoableSlice({
  name,
  reducers: {
    addStep(
      state,
      action: PayloadAction<{
        flowId: string;
        step: Step;
        toIdx?: number;
        parentId?: string;
        parentPath?: string;
        parentPathIdx?: number;
      }>
    ) {
      const { flowId, toIdx, step, parentId, parentPath, parentPathIdx } =
        action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (!existingFlow) return;

      if (parentId && parentPath) {
        const local = cloneDeep(existingFlow.present);
        const { steps } = local;
        const searchableId = parentId;

        const existingParentStep = findNestedStep(steps, searchableId);

        if (existingParentStep) {
          const stepsArray = (existingParentStep[parentPath] as Step[]) ?? [];
          const newSteps = [...stepsArray];

          if (parentPathIdx !== undefined) {
            const conditionStepsArray = newSteps[parentPathIdx].steps as Step[];
            const conditionSteps = [...conditionStepsArray];
            const start = isUndefined(toIdx) ? conditionSteps.length : toIdx;

            conditionSteps.splice(start, 0, step);

            newSteps[parentPathIdx].steps = [...conditionSteps];
          } else {
            const start = isUndefined(toIdx) ? stepsArray.length : toIdx;
            newSteps.splice(start, 0, step);

            existingParentStep[parentPath] = newSteps;
          }

          const newEntityState = { ...existingFlow.present, ...local };
          updateFlowEntity(state, newEntityState);
        }
      } else {
        const { steps } = existingFlow.present;
        const existingStep = steps.find((st) => st.id === step.id);

        if (!existingStep) {
          const newSteps = [...steps];
          const start = isUndefined(toIdx) ? steps.length : toIdx;
          newSteps.splice(start, 0, step);
          const newEntityState = { ...existingFlow.present, steps: newSteps };
          updateFlowEntity(state, newEntityState);
        }
      }
    },
    updateStep(state, action: PayloadAction<{ flowId: string; step: Step }>) {
      const { flowId, step } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const { present } = existingFlow;
        const updateIdx = getModifiableStepIndex(present.steps, step.id);

        if (updateIdx !== -1) {
          const local = cloneDeep(existingFlow.present);
          const { steps } = local;

          const isNestedStep =
            steps[updateIdx].stepType === Steps.SWITCH &&
            steps[updateIdx].id !== step.id;

          if (isNestedStep) {
            const switchStep = steps[updateIdx];

            updateNestedStep(switchStep, step);

            const newEntityState = { ...existingFlow.present, ...local };
            updateFlowEntity(state, newEntityState);
          } else {
            const newSteps = [...steps];
            newSteps.splice(updateIdx, 1, step);

            const newEntityState = { ...existingFlow.present, steps: newSteps };
            updateFlowEntity(state, newEntityState);
          }
        }
      }
    },
    removeStep(
      state,
      action: PayloadAction<{ flowId: string; stepId: string }>
    ) {
      const { stepId, flowId } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const { present } = existingFlow;
        const removalIdx = getModifiableStepIndex(present.steps, stepId);

        if (removalIdx !== -1) {
          const local = cloneDeep(existingFlow.present);
          const { steps } = local;

          const isNestedStep =
            steps[removalIdx].stepType === Steps.SWITCH &&
            steps[removalIdx].id !== stepId;

          if (isNestedStep) {
            const switchStep = steps[removalIdx];
            deleteNestedStep(switchStep, stepId);

            const newEntityState = { ...existingFlow.present, ...local };
            updateFlowEntity(state, newEntityState);
          } else {
            const newSteps = [...steps];
            newSteps.splice(removalIdx, 1);

            const newEntityState = { ...existingFlow.present, steps: newSteps };
            updateFlowEntity(state, newEntityState);
          }
        }
      }
    },
    reorderStepListItem(
      state,
      action: PayloadAction<{ flowId: string; fromIdx: number; toIdx: number }>
    ) {
      const { flowId, toIdx, fromIdx } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const steps = reorder(existingFlow.present.steps, fromIdx, toIdx);
        const newEntityState = { ...existingFlow.present, steps };
        updateFlowEntity(state, newEntityState);
      }
    },
    updateWithFlowDraft(state, action: PayloadAction<Flow>) {
      const flow = action.payload;
      const draftValue = YAML.stringify(serializeFlow(flow));

      return updateEntityWithDraft(state, flow, draftValue);
    },
    redoWithDraft(state, action: PayloadAction<string>) {
      const flowId = action.payload;

      redoEntity(state, flowId);
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow && existingFlow.draft) {
        existingFlow.draft = YAML.stringify(
          serializeFlow(existingFlow.present)
        );
      }
    },
    undoWithDraft(state, action: PayloadAction<string>) {
      const flowId = action.payload;

      undoEntity(state, flowId);
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow && existingFlow.draft) {
        existingFlow.draft = YAML.stringify(
          serializeFlow(existingFlow.present)
        );
      }
    },
    updateSwitchStepCaseCondition(
      state,
      action: PayloadAction<{
        flowId: string;
        index: number;
        stepId: string;
        condition: string;
      }>
    ) {
      const { flowId, index, stepId, condition } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const local = cloneDeep(existingFlow.present);
        const { steps } = local;

        const updateCaseConditionFn = (entity: Step) => {
          const workableEntries = Array.isArray(entity.cases)
            ? entity.cases
            : [];

          const updatableCaseEntry = workableEntries[index];

          if (updatableCaseEntry) {
            updatableCaseEntry.condition = condition;
          }

          const newEntityState = { ...existingFlow.present, ...local };
          updateFlowEntity(state, newEntityState);
        };

        modifyNestedStep(steps, stepId, updateCaseConditionFn);
      }
    },
    addSwitchStepCase(
      state,
      action: PayloadAction<{
        flowId: string;
        stepId: string;
        condition: string;
      }>
    ) {
      const { flowId, stepId, condition } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const local = cloneDeep(existingFlow.present);
        const { steps } = local;

        const addCaseFn = (entity: Step) => {
          if (Array.isArray(entity.cases)) {
            entity.cases = [
              ...entity.cases,
              {
                condition,
                steps: [],
              },
            ];
          } else {
            entity.cases = [
              {
                condition,
                steps: [],
              },
            ];
          }

          const newEntityState = { ...existingFlow.present, ...local };
          updateFlowEntity(state, newEntityState);
        };

        modifyNestedStep(steps, stepId, addCaseFn);
      }
    },
    deleteSwitchStepCase(
      state,
      action: PayloadAction<{
        flowId: string;
        index: number;
        stepId: string;
      }>
    ) {
      const { flowId, index, stepId } = action.payload;
      const existingFlow = selectEntity<Flow>(state, flowId);

      if (existingFlow) {
        const local = cloneDeep(existingFlow.present);
        const { steps } = local;

        const deleteCaseFn = (entity: Step) => {
          if (Array.isArray(entity.cases)) {
            const newCases = [...entity.cases];
            newCases.splice(index, 1);

            entity.cases = newCases;
          }

          const newEntityState = { ...existingFlow.present, ...local };
          updateFlowEntity(state, newEntityState);
        };

        modifyNestedStep(steps, stepId, deleteCaseFn);
      }
    },
  },
  predefinedThunks: {
    openEntityAction: openFlowAction,
    closeEntityTabAction: closeFlowTabAction,
    deleteEntityAction: deleteFlowAction,
    deleteFolderAction: deleteFlowFolderAction,
    overwriteFolderAction: overwriteFlowFolderAction,
    loadEntityAction: loadFlowAction,
    storeEntityAction: storeFlowAction,
    refreshEntitiesAction: refreshFlowsAction,
  },
  extraReducers: (builder) => {
    builder.addCase(
      generateDataForInputSchemaAction.fulfilled,
      (state, action) => {
        const flow = action.payload;
        const draftValue = YAML.stringify(serializeFlow(flow));

        return updateEntityWithDraft(state, flow, draftValue);
      }
    );
  },
});

export const {
  update,
  updateDraft,
  updateWithFlowDraft,
  updateOmitHistory,
  add,
  addTargetFolder,
  remove,
  redoWithDraft,
  undoWithDraft,
  addStep,
  updateStep,
  removeStep,
  reorderStepListItem,
  markDraftAsValid,
  markDraftAsInvalid,
  removeAll,
  updateSwitchStepCaseCondition,
  addSwitchStepCase,
  deleteSwitchStepCase,
} = flowsSlice.actions;

export default flowsSlice.reducer;
