import {
  FlowGenerator,
  getApiOperationList,
  SerializedFlow,
  WORKSPACE_SCHEMAS,
} from "@sapiens-digital/ace-designer-common";
import * as workspacePaths from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import {
  FORCED_VERSION_DISABLED,
  UN_VERSIONED_ENTITY_VAL,
} from "constant/uiConstants";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { AceApiOperation, Api, Flow, SerializedApi } from "model";
import { Schema, SerializedSchema } from "model/schemas";
import { Workspace } from "model/workspace";
import { OpenAPIV3 } from "openapi-types";
import { saveFlow } from "services/flows";
import { saveSchema } from "services/schemas";
import {
  extractSchemaNames,
  removeVersionSuffix,
  renameSchemasInObj,
  updateDeprecatedRefFormatInSchema,
} from "services/workspace/schemaReferences";
import { EntityPaths } from "store/schemas/selectors";
import { omitExtension } from "store/utils/path";
import { v4 as uuidv4 } from "uuid";

import {
  SchemaDifference,
  SchemaDifferenceType,
} from "./apis-diff/getSchemaDifferences";

const MATCH_OPEN_API_VERSION = /3\.\d{1}\.\d{1}/;

interface EntityVersionHistory {
  maxVersion: number;
  oldVersionIds: Set<string>;
}

export type OperationIdVersionMap = Record<string, number>;

export const getAllOperationIdsWithVersion = (
  originalApiFile: Api
): OperationIdVersionMap => {
  const mapping: OperationIdVersionMap = {};

  getApiOperationList(originalApiFile.paths, "old").forEach(
    ({ operationId }) => {
      if (operationId) {
        const opidWithoutversion = removeVersionSuffix(operationId);

        if (opidWithoutversion) {
          const v = parseInt(
            operationId.replace(`${opidWithoutversion}V`, ""),
            10
          );
          mapping[opidWithoutversion] = v;
        }
      }
    }
  );

  return mapping;
};

export const getVersionedOperationId = (
  operationId: string,
  existingOperationIds: OperationIdVersionMap,
  forcedVersion?: number
): {
  operationId: string;
  operationIdWithoutversion: string;
  maxVersion: number;
} => {
  let newVersion = 1;

  if (forcedVersion && forcedVersion !== FORCED_VERSION_DISABLED) {
    newVersion = forcedVersion;
  } else {
    const maxVersion = existingOperationIds[operationId];

    if (maxVersion && maxVersion >= 1) {
      newVersion = maxVersion + 1;
    }
  }

  return {
    operationId: `${operationId}V${newVersion}`,
    maxVersion: newVersion,
    operationIdWithoutversion: operationId,
  };
};

// mutates the apiFile to update operationId for each api operation
export const versionOperationIds = async (
  apiFile: SerializedApi,
  existingApiFile: Api,
  forcedVersion?: number
): Promise<SerializedApi> => {
  const isForcedVersion =
    forcedVersion && forcedVersion !== FORCED_VERSION_DISABLED;

  const newOperations = getApiOperationList(apiFile.paths, "imported");

  const existingOperationIds = isForcedVersion
    ? {}
    : getAllOperationIdsWithVersion(existingApiFile);

  const versionOperationId = async (operation: AceApiOperation) => {
    if (operation.operationId) {
      const { operationId, maxVersion, operationIdWithoutversion } =
        getVersionedOperationId(
          operation.operationId,
          existingOperationIds,
          forcedVersion
        );

      operation.operationId = operationId;

      if (!isForcedVersion) {
        existingOperationIds[operationIdWithoutversion] = maxVersion;
      }
    }

    const { path, verb, id, ...rest } = operation;

    if (apiFile.paths[path]) {
      apiFile.paths[path]![verb] = rest;
    } else {
      apiFile.paths[path] = {
        [verb]: rest,
      };
    }
  };

  await Promise.all(newOperations.map(versionOperationId));

  return apiFile;
};

export const createFlowForApiOperation = async (
  operation: AceApiOperation,
  apiFile: SerializedApi,
  workspace: Workspace,
  flowGenerator: FlowGenerator,
  nameUpdater: (id: string, name: string) => string
): Promise<string> => {
  const flow = flowGenerator.generateFlow(
    operation,
    apiFile.info,
    apiFile.components || {}
  );

  const newFlowId = uuidv4();

  flow.name = nameUpdater(newFlowId, flow.name);

  const flowPath = `${flow.name}.yaml`;
  await saveFlow(
    {
      ...(updateDeprecatedRefFormatInSchema(
        flow.content,
        workspacePaths.WORKSPACE_SCHEMAS
      ) as SerializedFlow),
      name: flow.name,
      id: newFlowId,
    } as Flow,
    workspace
  );

  return flowPath;
};

type AddFlowsToApiOperationParams = {
  operations: AceOperationVersionInfo[];
  apiFile: SerializedApi;
  workspace: Workspace;
  existingFlowPaths: Record<string, string>;
  disableVersioning?: boolean;
  forcedVersion?: number;
};

// mutates the apiFile to add x-ace-flow for each api operation
export const addVersionedFlowsToApiOperations = async ({
  operations,
  apiFile,
  workspace,
  existingFlowPaths,
  disableVersioning,
  forcedVersion,
}: AddFlowsToApiOperationParams): Promise<Set<string>> => {
  const flowGenerator = new FlowGenerator();
  const flowPaths = { ...existingFlowPaths };
  const flowsForReview: Set<string> = new Set();

  const addFlowToOperation = async (data: AceOperationVersionInfo) => {
    const flowNameUpdater = (flowId: string, flowName: string): string => {
      if (!disableVersioning) {
        const { newName, oldVersionIds } = getVersionNameWithHistory({
          existingPaths: flowPaths,
          entityName: flowName,
          forcedVersion,
        });
        flowPaths[flowId] = newName;
        oldVersionIds.forEach((id) => flowsForReview.add(id));
        return newName;
      }

      return flowName;
    };

    data.operation["x-ace-flow"] = await createFlowForApiOperation(
      data.operation,
      apiFile,
      workspace,
      flowGenerator,
      flowNameUpdater
    );
  };

  await Promise.all(operations.map(addFlowToOperation));

  return flowsForReview;
};

export interface AceOperationVersionInfo {
  operation: AceApiOperation;
  version?: number;
}

export const getApiFileWithSelectedPaths = (
  apiFile: SerializedApi,
  operations: AceOperationVersionInfo[]
): SerializedApi => {
  const editedApiFile = cloneDeep(apiFile);
  editedApiFile.paths = {};

  operations.forEach(({ version, operation }) => {
    const { path, verb, ...rest } = operation;
    let apiPath = path;

    if (version) {
      apiPath = `/v${version}${path}`;
    }

    if (editedApiFile.paths[apiPath]) {
      editedApiFile.paths[apiPath]![verb] = rest;
    } else {
      editedApiFile.paths[apiPath] = { [verb]: rest };
    }
  });

  return editedApiFile;
};

const extractUniqueReferencedSchemas = (
  editedApiFile: SerializedApi,
  uploadedApiFile: SerializedApi
): string[] =>
  extractSchemaNames(editedApiFile.components?.schemas || {}).filter(
    (schemaname) =>
      !editedApiFile.components?.schemas?.[schemaname] &&
      uploadedApiFile.components?.schemas?.[schemaname]
  );

const getMergedSchemas = (
  uploadedApiFile: SerializedApi,
  schemaNames: string[]
) =>
  Object.fromEntries(
    Object.entries(uploadedApiFile.components?.schemas || {}).filter(([key]) =>
      schemaNames.includes(key)
    )
  );

export const removeUnreferencedSchemas = (
  apiFile: SerializedApi
): SerializedApi => {
  const editedApiFile = cloneDeep(apiFile);

  if (apiFile.components?.schemas) {
    const schemasToImport = extractSchemaNames(editedApiFile.paths);
    editedApiFile.components = {
      ...editedApiFile.components,
      schemas: getMergedSchemas(apiFile, schemasToImport),
    };

    if (editedApiFile.components.schemas) {
      let refencedSchemasToImport: string[] = extractUniqueReferencedSchemas(
        editedApiFile,
        apiFile
      );

      while (refencedSchemasToImport.length) {
        editedApiFile.components.schemas = {
          ...editedApiFile.components.schemas,
          ...getMergedSchemas(apiFile, refencedSchemasToImport),
        };
        refencedSchemasToImport = extractUniqueReferencedSchemas(
          editedApiFile,
          apiFile
        );
      }
    }
  }

  return editedApiFile;
};

export const mergeAPIs = async (
  apiFile: SerializedApi,
  originalApiFile: Api
): Promise<Api> => {
  const newOperations = getApiOperationList(apiFile.paths, "imported");

  const addAPIOperationToOriginalFile = async (operation: AceApiOperation) => {
    const { path, verb, id, ...rest } = operation;

    if (originalApiFile.paths[path]) {
      originalApiFile.paths[path]![verb] = rest;
    } else {
      originalApiFile.paths[path] = {
        [verb]: rest,
      };
    }
  };

  await Promise.all(newOperations.map(addAPIOperationToOriginalFile));
  return originalApiFile;
};

export type ExtractedApiSchema = {
  name: string;
  content: OpenAPIV3.ReferenceObject | OpenAPIV3.SchemaObject;
};

export type SchemaName = string;

export const saveLocalizedSchemasForApi = async (
  api: SerializedApi,
  currentWorkspace: Workspace,
  targetPath?: string
): Promise<SchemaName[]> => {
  const extractedSchemas = extractSchemasFromAPIFile(api);
  const existingSchemaNames = extractedSchemas.map(({ name }) => name);

  const localizedSchemas: Schema[] = extractedSchemas.map(
    ({ name, content }) => ({
      fileName: name,
      file: updateDeprecatedRefFormatInSchema(content, "") as SerializedSchema,
      id: uuidv4(),
    })
  );

  await Promise.all(
    localizedSchemas.map(
      async (schema) => await saveSchema(schema, currentWorkspace, targetPath)
    )
  );

  return existingSchemaNames;
};

const extractSchemasFromAPIFile = (
  api: SerializedApi
): ExtractedApiSchema[] => {
  if (!api.components?.schemas) {
    return [];
  }

  return Object.entries(api.components.schemas).map(([name, content]) => ({
    name,
    content,
  }));
};

export const assertApiVersion = (api: SerializedApi): void => {
  if (!MATCH_OPEN_API_VERSION.test(api.openapi)) {
    throw new Error(
      "Invalid OpenAPI version. Please verify that schema is valid OpenAPI document."
    );
  }
};

export function assertSelectedApi(api: Api | undefined): asserts api is Api {
  if (!api) {
    throw new Error("No API selected.");
  }
}

export const getVersionHistoryForEntity = (
  existingPaths: EntityPaths,
  entityName: string,
  forcedVersion?: number
): EntityVersionHistory => {
  const entityVersionHistory: EntityVersionHistory = {
    maxVersion: UN_VERSIONED_ENTITY_VAL,
    oldVersionIds: new Set(),
  };

  const unVersionedOldEntity = Object.entries(existingPaths).find(
    ([_, path]) => path === entityName
  );

  if (unVersionedOldEntity) {
    entityVersionHistory.oldVersionIds.add(unVersionedOldEntity[0]);
  }

  let maxVersion = UN_VERSIONED_ENTITY_VAL;
  const entityNameMatchRegEx = new RegExp(`^${entityName}(Vd*)`, "g");

  for (const [existingEntityId, existingEntityName] of Object.entries(
    existingPaths
  )) {
    const matchData = existingEntityName.match(entityNameMatchRegEx);

    if (matchData && matchData[0]) {
      const v = parseInt(existingEntityName.replace(matchData[0], ""));

      if (forcedVersion && forcedVersion !== UN_VERSIONED_ENTITY_VAL) {
        if (v !== forcedVersion) {
          entityVersionHistory.oldVersionIds.add(existingEntityId);
        }
      } else {
        entityVersionHistory.oldVersionIds.add(existingEntityId);
      }

      if (v >= maxVersion) {
        maxVersion = v;
      }
    }
  }

  entityVersionHistory.maxVersion = maxVersion;
  return entityVersionHistory;
};

interface EntityVersionNameWithHistory {
  newName: string;
  oldVersionIds: Set<string>;
}

type GetVersionNameWithHistoryParams = {
  existingPaths: EntityPaths;
  entityName: string;
  existingVersionMapping?: SchemaVersionMap;
  forcedVersion?: number;
};

/**
 * @returns new name with version and old version ids of entities
 */
export const getVersionNameWithHistory = ({
  existingPaths,
  entityName,
  existingVersionMapping,
  forcedVersion,
}: GetVersionNameWithHistoryParams): EntityVersionNameWithHistory => {
  let newVersion = 1;
  let existingHistory: Set<string> = new Set();
  let oldMaxVersion = UN_VERSIONED_ENTITY_VAL;

  if (existingVersionMapping) {
    oldMaxVersion = existingVersionMapping[entityName];
  } else {
    const { maxVersion, oldVersionIds } = getVersionHistoryForEntity(
      existingPaths,
      entityName,
      forcedVersion
    );
    oldMaxVersion = maxVersion;
    existingHistory = oldVersionIds;
  }

  if (oldMaxVersion > 0) {
    newVersion = oldMaxVersion + 1;
  }

  if (forcedVersion && forcedVersion !== FORCED_VERSION_DISABLED) {
    newVersion = forcedVersion;
  }

  return {
    newName: `${entityName}V${newVersion}`,
    oldVersionIds: existingHistory,
  };
};

/**
 * @param schemaRenameMap Map of old name and new name of schemas.
 * @returns updated api file with new schema names in component.schemas
 */
export const renameSchemasInApiFile = (
  apiFile: SerializedApi,
  schemaRenameMap: Record<string, string>
): SerializedApi => {
  const renamedApiFile = cloneDeep(apiFile);
  Object.entries(schemaRenameMap).forEach(
    ([schemaName, versionedSchemaName]) => {
      if (
        renamedApiFile.components?.schemas?.[schemaName] &&
        schemaName !== versionedSchemaName
      ) {
        renamedApiFile.components.schemas[versionedSchemaName] =
          renamedApiFile.components.schemas[schemaName];
        delete renamedApiFile.components.schemas[schemaName];
      }
    }
  );

  return renamedApiFile;
};

/**
 * @param oldSchemas The key value pair of schema name and schema content
 * @returns List of schema names from api file which are not matching old schema
 */
export const getChangedSchemaNames = (
  apiFile: SerializedApi,
  oldSchemas: Record<string, unknown>
): string[] => {
  const newChangedSchemas: string[] = [];

  Object.entries(oldSchemas).forEach(([k, v]) => {
    if (apiFile.components?.schemas?.[k]) {
      const serializedSchema = updateDeprecatedRefFormatInSchema(
        apiFile.components.schemas[k],
        ""
      ) as SerializedSchema;

      if (!isEqual(serializedSchema, v)) {
        newChangedSchemas.push(k);
      }
    }
  });

  return newChangedSchemas;
};

export const getChangedAndAddedSchemaNames = (
  schemaDiff: SchemaDifference[]
): string[] =>
  schemaDiff
    .filter(
      (d) =>
        d.diffStatus === SchemaDifferenceType.Changed ||
        d.diffStatus === SchemaDifferenceType.Added
    )
    .map((d) => d.newNameId);

export type SchemaVersionMap = Record<string, number>;

export const renameUnchangedSchemasToLatestVersion = (
  apiFile: SerializedApi,
  versionedApiFile: SerializedApi,
  schemasVersionMapping: SchemaVersionMap,
  schemaRenamedMap: Record<string, string>
): SerializedApi => {
  const unChangedRenameMap: Record<string, string> = {};

  for (const schemaname of Object.keys(apiFile.components?.schemas || {})) {
    if (!schemaRenamedMap[schemaname]) {
      delete versionedApiFile.components?.schemas?.[schemaname];

      if (
        schemasVersionMapping[schemaname] &&
        schemasVersionMapping[schemaname] >= 0
      ) {
        unChangedRenameMap[
          schemaname
        ] = `${schemaname}V${schemasVersionMapping[schemaname]}`;
      }
    }
  }

  if (Object.keys(unChangedRenameMap).length > 0) {
    versionedApiFile = renameSchemasInObj(
      versionedApiFile,
      unChangedRenameMap
    ) as SerializedApi;
  }

  return updateDeprecatedRefFormatInSchema(
    versionedApiFile,
    WORKSPACE_SCHEMAS,
    Object.values(unChangedRenameMap)
  ) as SerializedApi;
};

type GetVersionedApiFileParams = {
  apiFile: SerializedApi;
  schemaPaths: EntityPaths;
  schemasVersionMapping: SchemaVersionMap;
  forcedVersion?: number;
  selectedSchemas?: string[];
};

/**
 * @returns new api file with versioned schemas and updated api operations
 */
export const getSchemasVersionedApiFile = ({
  apiFile,
  schemaPaths,
  schemasVersionMapping,
  forcedVersion,
  selectedSchemas,
}: GetVersionedApiFileParams): SerializedApi => {
  let versionedApiFile = cloneDeep(apiFile);

  let continueVersioning = true;
  const schemaRenameMap: Record<string, string> = {};
  const schemasToVersion = selectedSchemas || [];

  while (continueVersioning) {
    const notModfiedSchemas: Record<string, unknown> = {};

    if (versionedApiFile.components?.schemas) {
      for (const schemaname of Object.keys(
        versionedApiFile.components.schemas
      )) {
        if (
          !versionedApiFile.components.schemas[schemaname] ||
          Object.values(schemaRenameMap).includes(schemaname)
        ) {
          continue;
        }

        if (
          schemasToVersion.includes(schemaname) ||
          (forcedVersion && forcedVersion !== FORCED_VERSION_DISABLED)
        ) {
          const { newName } = getVersionNameWithHistory({
            existingPaths: schemaPaths,
            entityName: schemaname,
            existingVersionMapping: schemasVersionMapping,
            forcedVersion,
          });

          if (newName !== schemaname) {
            schemaRenameMap[schemaname] = newName;
          }
        } else {
          notModfiedSchemas[schemaname] = updateDeprecatedRefFormatInSchema(
            versionedApiFile.components.schemas[schemaname],
            ""
          );
        }
      }

      versionedApiFile = renameSchemasInObj(
        versionedApiFile,
        schemaRenameMap
      ) as SerializedApi;

      versionedApiFile = renameSchemasInApiFile(
        versionedApiFile,
        schemaRenameMap
      );
    }

    const newSchemastoVersion = getChangedSchemaNames(
      versionedApiFile,
      notModfiedSchemas
    );
    schemasToVersion.push(...newSchemastoVersion);
    continueVersioning = newSchemastoVersion.length > 0;
  }

  versionedApiFile = renameUnchangedSchemasToLatestVersion(
    apiFile,
    versionedApiFile,
    schemasVersionMapping,
    schemaRenameMap
  );

  return versionedApiFile;
};

export type UnVersionedApiWithFlowsMap = Map<string, Set<string>>;

export const getFlowIdsFromAllApiVersions = (
  existingFlowPaths: Record<string, string>,
  apiOperations: AceApiOperation[],
  apiPathsWithFlowsMap: UnVersionedApiWithFlowsMap
): string[] => {
  const result: string[] = [];

  apiOperations.forEach((apiOp) => {
    const flowPath = apiOp["x-ace-flow"];
    const allFlows = apiPathsWithFlowsMap?.get(apiOp.path + apiOp.verb);
    const flowPathsToReview: string[] = [];

    if (flowPath) {
      flowPathsToReview.push(flowPath);
    }

    if (allFlows) {
      flowPathsToReview.push(...Array.from(allFlows));
    }

    flowPathsToReview.forEach((flowPath) => {
      const matchedEntity = Object.entries(existingFlowPaths).find(
        ([_, path]) => path === omitExtension(flowPath)
      );

      if (matchedEntity) {
        result.push(matchedEntity[0]);
      }
    });
  });

  return result;
};

export const removeUnSelectedSchemas = (
  apiFile: SerializedApi,
  selectedSchemas: string[],
  schemasVersionMapping: SchemaVersionMap,
  disableVersioning?: boolean
): SerializedApi => {
  let editedApiFile = cloneDeep(apiFile);

  editedApiFile.components = {
    ...editedApiFile.components,
    schemas: {},
  };

  selectedSchemas.forEach((s) => {
    if (apiFile.components?.schemas && editedApiFile.components?.schemas) {
      editedApiFile.components.schemas[s] = apiFile.components?.schemas[s];
    }
  });

  if (disableVersioning) {
    return editedApiFile;
  }

  const unSelectedSchemas = Object.keys(
    apiFile.components?.schemas || {}
  ).filter((s) => !selectedSchemas.includes(s));

  const unSelectedRenameMap: Record<string, string> = {};

  for (const schemaname of unSelectedSchemas) {
    if (
      !selectedSchemas.includes(schemaname) &&
      schemasVersionMapping[schemaname] > 0
    ) {
      unSelectedRenameMap[
        schemaname
      ] = `${schemaname}V${schemasVersionMapping[schemaname]}`;
    }
  }

  if (Object.keys(unSelectedRenameMap).length > 0) {
    editedApiFile = renameSchemasInObj(
      editedApiFile,
      unSelectedRenameMap
    ) as SerializedApi;
  }

  return editedApiFile;
};
